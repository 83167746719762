<template lang="pug">
basicPages(:title="$t('faq.title')")
  h2(v-html="$t('faq.question1')")
  p(v-html="$t('faq.box1.text1')")
  p(v-html="$t('faq.box1.text2')")
  ul
    li(v-html="$t('faq.box1.li1')")
    li(v-html="$t('faq.box1.li3')")
    li(v-html="$t('faq.box1.li4')")

  p(v-html="$t('faq.box1.text3')")
  p(v-html="$t('faq.box1.text4')")

  h2(v-html="$t('faq.question3')")
  p(v-html="$t('faq.box3.text1', [languages])")
  p(v-html="$t('faq.box3.text2')")
  p(v-html="$t('faq.box3.text3')")
  //- box4
  h2(v-html="$t('faq.question4')")
  p(v-html="$t('faq.box4.text1')")
  p(v-html="$t('faq.box4.text2')")
  ul
    li(v-html="$t('faq.box4.li1')")
    li(v-html="$t('faq.box4.li2')")
    li(v-html="$t('faq.box4.li3')")
    li(v-html="$t('faq.box4.li4')")
    li(v-html="$t('faq.box4.li5')")
    li(v-html="$t('faq.box4.li6')")
    li(v-html="$t('faq.box4.li7')")
    li(v-html="$t('faq.box4.li8')")
    li(v-html="$t('faq.box4.li9')")
    li(v-html="$t('faq.box4.li10')")

  //- box5
  h2(v-html="$t('faq.question5')")
  p(v-html="$t('faq.box5.text1')")
  p(v-html="$t('faq.box5.text2')")
  p(v-html="$t('faq.box5.text3')")

  //- box6
  h2(v-html="$t('faq.question6')")
  p(v-html="$t('faq.box6.text1')")
  p(v-html="$t('faq.box6.text2')")
  p(v-html="$t('faq.box6.text3')")

  h2(v-html="$t('faq.question7')")
  p(v-html="$t('faq.box7.text1')")
  p(v-html="$t('faq.box7.text2')")
  p(v-html="$t('faq.box7.text3')")
  p(v-html="$t('faq.box7.text4')")

</template>

<script>
import basicPages from "@/components/structure/views/basicPages.vue";
export default {
  name: "Faq",
  components: {
    basicPages,
  },
  computed: {
    languages() {
      const languages = this.$tm("languages");
      return Object.values(languages).reduce((t, value) => {
        if (t == "") return value.text;
        return t + ", " + value.text;
      }, "") + '.';
    },
  },
};
</script>

<style lang="scss">
</style>
